<template>
  <div
    class="absolute bg-white shadow-md min-w-80 px-4 py-2 z-10"
    data-cy="app-tracking-token-modal"
    :style="positionStyle"
  >
    <div class="flex flex-col space-y-2">
      <lf-h4>
        {{
          $t(
            "ORGANIZATION.COMMUNICATION_TEMPLATES.ENTER_APP_TRACKING_TOKEN_LINK"
          )
        }}
      </lf-h4>
      <div class="max-h-30 overflow-y-auto flex flex-col space-y-2">
        <div
          v-for="(input, index) in inputs"
          :key="input.id"
          class="flex gap-x-2"
        >
          <input
            :value="input.value"
            ref="inputGroup"
            name="value"
            :data-cy="`app-tracking-token-value-${index}`"
            class="text-sm placeholder-gray-400 rounded hover:border-gray-300 focus:border-primary focus:outline-none focus:ring-0 border border-gray-200 grow"
            :placeholder="$t('COMMON.TOKEN')"
            @input="input.value = ($event.target as HTMLInputElement)?.value"
          />
          <div class="min-w-20">
            <div v-if="!!inputs[index].value" class="gap-x-2 flex items-center">
              <div class="min-w-8">
                <button
                  v-if="index"
                  v-tooltip="`${$t('COMMON.DELETE')} ${$t('COMMON.TOKEN')}`"
                  type="button"
                  :data-cy="`app-delete-tracking-token-value-${index}`"
                  class="p-2 rounded-4 w-fit-content justify-self-end"
                  @click="deleteInput(input.id)"
                >
                  <icon-base :icon="IconDelete" />
                </button>
              </div>
              <button
                v-if="index === inputs.length - 1"
                v-tooltip="`${$t('COMMON.ADD')} ${$t('COMMON.TOKEN')}`"
                type="button"
                :data-cy="`app-add-tracking-token-value-${index}`"
                class="p-2 rounded-4 w-fit-content justify-self-end"
                @click="addInput"
              >
                <icon-base :icon="IconAdd" :width="20" :height="20" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex items-center justify-end space-x-3">
        <button
          :class="hasSomeValue ? 'text-primary' : 'text-disabled-medium'"
          data-cy="app-tracking-token-save"
          :disabled="!hasSomeValue"
          @click.prevent="saveValues"
        >
          {{ $t("COMMON.SAVE") }}
        </button>
        <button
          class="text-error"
          data-cy="app-tracking-token-cancel"
          @click.prevent="emit('value:save', '')"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import LfH4 from "@/components/ui/text/LfHeading4.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconAdd from "@/components/icons/deals/IconAdd.vue";
import IconDelete from "@/components/icons/IconDelete.vue";

import { computed, nextTick, onMounted, ref } from "vue";
import { v4 } from "uuid";
import { appTrackingTokenKey } from "@/helpers/textEditor";
const { position } = defineProps<{
  position: {
    bottom: number;
    left: number;
    right: number;
    top: number;
    height: number;
  };
}>();

const emit = defineEmits<{
  "value:save": [value: string];
}>();

const barHeight = 45;
const leftOffset = 20;

const inputGroup = ref<HTMLInputElement[] | null>(null);

const inputs = ref<{ value: string; id: string }[]>([{ value: "", id: v4() }]);

const hasSomeValue = computed(() => inputs.value.some(({ value }) => !!value));

const positionStyle = computed(() => {
  const topPositon = position.top + position.height + barHeight;
  const leftPosition = position.left - leftOffset;
  return {
    top: `${topPositon}px`,
    left: `${leftPosition}px`
  };
});

const saveValues = () => {
  const value = inputs.value
    .map(({ value }) => value)
    .filter((value) => !!value)
    .map((item, index) =>
      index
        ? `${appTrackingTokenKey}${item}`
        : `__${appTrackingTokenKey}${item}`
    )
    .join(",");
  emit("value:save", value);
};

const addInput = () => {
  inputs.value.push({ value: "", id: v4() });
  nextTick(() => {
    inputGroup.value?.[inputs.value.length - 1]?.focus();
  });
};

const deleteInput = (id: string) => {
  const index = inputs.value.findIndex((input) => input.id === id);

  if (index === -1) {
    return;
  }

  inputs.value.splice(index, 1);
};

onMounted(() => {
  inputGroup.value?.[0]?.focus();
});
</script>
